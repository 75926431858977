<template>
      <section id="welcome" class="overflow-hidden">
    <v-row no-gutters>
      <v-col class="hidden-sm-and-down" md="6">
        <v-img :src="require('@/assets/verf-zwart.jpeg')" height="100vh" />
      </v-col>

      <v-col
        class="align-content-space-between layout wrap"
        cols="12"
        md="6"
        :pa-5="$vuetify.breakpoint.smAndDown"
      >
        <!-- <base-bubble-1 style="transform: rotate(180deg) translateY(25%)" /> -->
        
        <v-row align="start" justify="center">
          <v-col cols="11" md="11">
            <br/>
            <br/>
            <base-heading>Werkwijze</base-heading>
            <base-text>
            In de behandeling van uiteenlopende psychische problemen werk ik
oplossingsgericht, dat betekent dat ik zoek met de ander naar zijn of haar kracht en
herstelvermogen. De focus ligt in het hier en nu voor de behandeling: hoe kun je
verder? Daarin is ruimte om naar je levensverhaal te kijken en wat je wilt veranderen.
<br/>
<br/>
Clientgericht, ervaringsgericht en lichaamsgericht zijn woorden die bij mijn werkwijze
passen. Binnen de Generalistische Basis GGZ blijken meestal ongeveer twaalf
gesprekken genoeg te zijn. Wanneer u klachten hebt kunt u zich wenden tot de
huisarts. Deze kan een verwijsbrief maken en sturen naar mij. Belangrijk is dat u
zichzelf aanmeldt via het aanmeldformulier.
            </base-text>
            <router-link to="/aanmelden">
            <v-btn color="primary"
        elevation="2" 
        >Aanmelden</v-btn></router-link>
            <!-- <base-btn class="mt-4"> Lees meer! </base-btn> -->
          </v-col>
        </v-row>
<!-- 
        <base-bubble-2
          style="transform: rotate(180deg) translate(-200px, -15%)"
        /> -->
      </v-col>
    </v-row>
  </section>
    <!-- <div>
    <h1 class="display-2 font-weight-bold mb-3"></h1>
      </v-col> 
      <p>
        </p>
        </v-col>  
          <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">Aanmelden</h1>
      </v-col>  
          <h2 class="headline font-weight-bold mb-3"> Wachttijden en aanwezigheid </h2>
          De wachttijd is momenteel ongeveer 4 weken. Ik ben bereikbaar op dinsdag, woensdag en vrijdag. Bij spoed of
          crisis kun je je wenden tot de huisarts. 
          
                    <h2 class="headline font-weight-bold mb-3"> Aanmeldformulier </h2>
          Via de huisarts kun je een verwijsbrief krijgen bij klachten. Wanneer je
          onderstaand formulier invult, neem ik zo snel mogelijk contact op.
                    <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">Kosten</h1>
      </v-col>  
     
         
                    <h2 class="headline font-weight-bold mb-3"> Zorgverzekering en vergoeding  </h2>
          De zorg binnen de Generalistische
          Basis Geestelijke Gezondheidszorg (GBGGZ) is vergoede zorg, het is
          ondergebracht in de basisverzekering van de zorgverzekeringswet. Wel
          hebt u te maken met het verplichte eigen risico van € 385 (2022) De
          zorgverzekeraar vergoedt alleen de behandeling van psychische
          stoornisen zoals omschreven in de DSM 5, en onder voorwaarde dat u
          verwezen bent door de huisarts.<br/>  De verzekeraar vergoedt alleen
          psychologen of instellingen met wie zij een contract hebben. Met de
          meeste verzekeraars heb ik voor 2022 een contract afgesloten.
          Controleer bij uw verzekering of bij ons of uw behandeling vergoed
          wordt. <br/>De kosten van een gesprek die buiten de vergoeding van de
          verzekering valt bedraagt € 114,41 voor een gesprek van 45 minuten.
          Dit is bijvoorbeeld het geval als u meer sessies wilt dan de
          verzekering vergoedt of voor problemen die niet door de
          zorgverzekeraar worden vergoed. 
          <h2 class="headline font-weight-bold mb-3">  Afspraak wijzigen of annuleren  </h2>
         
          Wilt u
          zo snel mogelijk, of in ieder geval vóór 24 uur van te voren, een
          afspraak wijzigen of afzeggen wanneer dit nodig is. De zorgverzekaar
          vergoedt geen “no show”, vandaar dat de kosten voor uzelf zijn als u
          niet tijdig annuleert. Dit bedraagt €54,75. 
          Kwaliteitsbewaking 
          
          Ik ben
          lid van de beroepsvereniging NVGzP (Nederlandse Vereniging voor
          Gezondheidszorgpsychologie en haar specialismen). Ik voldoe aan de
          beroepseisen die hieruit voortvloeien. <br/> Mocht het zo zijn dat je van
          mening bent dat ik niet juist handel tijdens de behandeling, hoop ik
          dat je dat met mij wilt bespreken. Ik zal deze klacht serieus nemen om
          samen te zoeken naar een oplossing. Wanneer dit niet voldoende blijkt
          te zijn, kun je contact opnemen met de klachtfunctionaris van de
          NVGzP, te bereiken via: klachten@nvgzp.nl De klachtenregeling is hier
          te vinden. <br/>  Als psycholoog draag ik zorg voor geheimhouding van de
          dingen die besproken worden tijdens de gesprekken. Ik leg een
          persoonlijk dossier aan per behandeling, die bijvoorbeeld de
          verwijsbrief van de huisarts bevat, het behandelplan etc. Het wordt zo
          bewaard dat niemand anders dan de psycholoog toegang heeft tot de
          gegevens. Je hebt recht op inzage in het dossier, na afspraak met de
          mij. In de opslag van gegevens voldoe ik aan de AVG. Als de huisarts
          de verwijzende partij is, wordt deze kort geïnformeerd over de
          diagnose en het voorgesteld behandelplan. De huisarts wordt gezien als
          medebehandelaar en kan als nodig worden geconsulteerd. <br/> Het
          kwaliteitsstatuut van psychologenpraktijk Jantine Landman vindt u hier<br/>    
        </div> -->
</template>


<script>
export default {
  name: "Aanmelden",

  data: () => ({
    //
  }),
};
</script>