<template>
    <div>
      <!-- <v-btn v-for="n in 5" :key="n" @click="openDialog(n)">Open Dialog {{ n }}</v-btn> -->
      <v-dialog v-for="n in 5" :key="n" v-model="dialogs[n]" width="500">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on">Open Dialog {{ n }}</v-btn>
        </template>
        <v-card>
          <v-card-title>Dialog {{ n }}</v-card-title>
          <v-card-text>This is the content of dialog {{ n }}</v-card-text>
          <!-- <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogs[n] = false">Close</v-btn>
          </v-card-actions> -->
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        dialogs: Array(5).fill(false),
      };
    },

  };
  </script>
  