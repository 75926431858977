<template>
  <p
    class="title font-weight-light"
    style="line-height: 1.2 !important;"
  >
    <slot />
  </p>
</template>

<script>
  export default {
    name: 'BaseText',
  }
</script>
