<template>
  <section id="about" class="overflow-hidden">
    <v-row class="white" no-gutters>
      <v-col class="pa-5" cols="11" md="6">
        <!-- <base-bubble-1 style="transform: translate(5%, -5%)" /> -->
        

        <base-heading class="info--text"> Profiel </base-heading>

        <base-text class="mb-5">
          Ik ben opgeleid tot Gezondheidszorg psycholoog BIG (GZ-psycholoog).
          <br />
          Eerder heb ik gewerkt binnen het onderwijs als loopbaanbegeleider en
          als therapeut en onderzoeker binnen een instelling voor
          (jong)volwassenen en kinderen. Mijn opleiding psychologie heb ik aan
          de Rijksuniversiteit Groningen gevolgd met aansluitend een jaar
          geestelijke verzorging aan Universiteit van Tilburg. Daarna heb ik
          binnen meerdere huisartspraktijken ervaring opgedaan in het bieden van
          psychologische hulp op maat als praktijkondersteuner GGZ. Na mijn
          studie tot GZ- psycholoog ben ik sinds juni 2021 actief als
          vrijgevestigd GZ-psycholoog in de stad Groningen en op het Ommeland.
          Ik ben moeder en echtgenoot, en ik hou van kunst, theater en muziek
          maken en van buiten zijn.
          <br />
          <br />
          In mijn opleidingen en in de praktijk heb ik brede kennis opgedaan
          over psychische problemen. U kunt bij me terecht bij klachten
          uiteenlopend van spanningen, stressklachten en burnout tot depressie,
          angsten, rouw, levensfaseproblematiek en negatief zelfbeeld, vormen
          van verslaving en problemen op het gebied van seksualiteit of
          relaties, en traumaverwerking.
        </base-text>
      </v-col>

      <v-col class="hidden-sm-and-down" md="6">
        <v-img :src="require('@/assets/verf-blauw.jpeg')" height="100%" />
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  data: () => ({
    skills: [
      {
        name: "Web Design",
        value: 100,
      },
      {
        name: "Web Development",
        value: 75,
      },
      {
        name: "Web Support",
        value: 90,
      },
    ],
  }),
};
</script>
