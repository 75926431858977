<template>
  <section id="welcome" class="overflow-hidden">
    <v-row no-gutters>
      <v-col class="hidden-sm-and-down" md="6">
        <v-img :src="require('@/assets/verf-groen.jpeg')" height="100vh" />
      </v-col>

      <v-col
        class="align-content-space-between layout wrap"
        cols="12"
        md="6"
        :pa-5="$vuetify.breakpoint.smAndDown"
      >
        <!-- <base-bubble-1 style="transform: rotate(180deg) translateY(25%)" /> -->

        <v-row align="start" justify="center">
          <v-col cols="11" md="11">
            <br />
            <br />
            <base-heading>Vergoedingen en Kosten</base-heading>
            <base-text>
              De vergoeding van de Generalistische Basis Geestelijke
              Gezondheidszorg (GBGGZ) is ondergebracht in de basisverzekering
              van de zorgverzekeringswet. Het valt wel onder het verplichte
              eigen risico van € 385 (2024) Als u dat nog niet gebruikt hebt
              voor andere medische kosten, dan trekt de verzekering dit af van
              de vergoeding voor de zorg die u bij mij ontvangt. Ik heb voor
              2024 met de meeste verzekeraars een contract afgesloten.
              Uitzonderingen zijn CZ, ONVZ en ENO. Check bij uw verzekering of bij
              mij of uw behandeling vergoed wordt.

              <h4>Afspraak wijzigen of annuleren</h4>

              Wilt u zo snel mogelijk, of in ieder geval vóór 24 uur van te
              voren, een afspraak wijzigen of afzeggen wanneer dit nodig is. De
              zorgverzekaar vergoedt geen “no show”, vandaar dat de kosten voor
              uzelf zijn als u niet tijdig annuleert. Dit bedraagt €54,75.
            </base-text>

            <!-- <base-btn class="mt-4"> Lees meer! </base-btn> -->
          </v-col>
        </v-row>
        <!-- 
        <base-bubble-2
          style="transform: rotate(180deg) translate(-200px, -15%)"
        /> -->
      </v-col>
    </v-row>
  </section>
</template>


<script>
export default {
  name: "Aanmelden",

  data: () => ({
    //
  }),
};
</script>