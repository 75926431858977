<template>
  <section
    id="recent-projects"
    class="overflow-hidden"
  >
    <v-row no-gutters>
      <v-col
        class="primary pa-5 white--text"
        cols="12"
        md="6"
      >
        <!-- <base-bubble-1
          style="transform: rotate(180deg) translateX(25%)"
        /> -->

        <base-heading>
          Contact
        </base-heading>

        <base-text class="mb-5">    
        Jantine Landman-Zuur, gezondheidszorg psycholoog<br/>
Telefoonnummer: 0616590193<br/>
(bereikbaar op dinsdag, woensdag en vrijdag)<br/>
Emailadres: mail@jantinelandman.nl<br/>
KvK nummer: 63283115<br/>
BTW nummer: 106384090B01<br/>
BIG nummer: 09929289625<br/>
<br/>
Op dinsdag ben ik aanwezig op <a style="color: aliceblue;" href="https://www.haphoornsemeer.nl/" target="_blank">Huisartsenpraktijk Hoornsemeer</a>. Daar werk ik ook als praktijkondersteuner GGZ.
Schaaksport 132, 9728 PC Groningen<br/>
<br/>
Op woensdag ben ik aanwezig op <a style="color: aliceblue;" href="https://www.deschoolvisvliet.nl/" target="_blank"> De School </a>.
Bartolesstraat 2, 9845 AK Visvliet
<br/>
<br/>
Op vrijdag ben ik aanwezig in de praktijkruimte van collega  <a style="color: aliceblue;" href="https://www.yvonnehofman.nl/" target="_blank"> Yvonne Hofman</a>.
Gazellelaan 2, 9721 WK Groningen
        </base-text>

 
      </v-col>

      <v-col
        class="hidden-sm-and-down"
        md="6"
      >
         <v-img
          :src="require('@/assets/verf-rood.jpeg')"
          height="100%"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
  export default {
    name: 'RecentProjects',

    data: () => ({
      projects: [
        'project1',
        'project2',
        'project3',
        'project4',
      ],
    }),
  }
</script>
