<template>
    <div>
        <Welcome />
        <About />
        <Services />
        <Recentprojects />
    </div>
</template>

<script>
import Welcome from './components/Welcome';
import About from './components/AboutMe';
import Services from './components/Services';
import Recentprojects from './components/RecentProjects';
import Heading from './components/base/Heading.vue';

export default {
  name: 'HomePage',

  components: {

    Welcome,
    About,
    Services,
    Recentprojects,
    Heading,
  },

  data: () => ({
    //
  }),
};
</script>
