<template>
  <section id="welcome" class="overflow-hidden">
    <v-row no-gutters>
      <v-col class="hidden-sm-and-down" md="6">
        <v-img :src="require('@/assets/verf-zwart.jpeg')" height="100vh" />
      </v-col>

      <v-col
        class="align-content-space-between layout wrap"
        cols="12"
        md="6"
        :pa-5="$vuetify.breakpoint.smAndDown"
      >
        <!-- <base-bubble-1 style="transform: rotate(180deg) translateY(25%)" /> -->

        <v-row align="start" justify="center">
          <v-col cols="11" md="11">
            <br />
            <br />
            <base-heading>Kwaliteitsbewaking</base-heading>
            <base-text>
            
Ik ben lid van de beroepsvereniging van de LVVP (landelijke vereniging
vrijgevestigde psychologen) Ik voldoe aan de beroepseisen die hieruit voortvloeien.
Mocht u mening zijn dat ik niet juist handel tijdens de behandeling, hoop ik dat u dat
met mij wilt bespreken. Ik zal deze klacht serieus nemen om samen te zoeken naar
een oplossing. Wanneer dit niet voldoende blijkt te zijn, kunt u contact opnemen met
de klachtfunctionaris van de LVVP, te bereiken via deze  <a  href="https://lvvp.info/voor-clienten/wat-als-ik-ontevreden-ben-de-behandeling/klachtenregeling-volwassenen/" target="_blank">site</a>. Hier is ook de
klachtenregeling te vinden.

Als psycholoog draag ik zorg voor geheimhouding van de dingen die besproken
worden tijdens de gesprekken. In de opslag van gegevens voldoe ik aan de AVG.
Het kwaliteitsstatuut van psychologenpraktijk Jantine Landman vindt u <a  href="https://lvvp.info/voor-clienten/wat-als-ik-ontevreden-ben-de-behandeling/klachtenregeling-volwassenen/" target="_blank">hier</a>.
            </base-text>

            <!-- <base-btn class="mt-4"> Lees meer! </base-btn> -->
          </v-col>
        </v-row>
        <!-- 
        <base-bubble-2
          style="transform: rotate(180deg) translate(-200px, -15%)"
        /> -->
      </v-col>
    </v-row>
  </section>
</template>


<script>
export default {
  name: "Aanmelden",

  data: () => ({
    //
  }),
};
</script>