<template>
  <section id="services" class="overflow-hidden">
    <!-- <v-row class="accent" no-gutters> -->
    <v-row  no-gutters>
      <v-col class="hidden-sm-and-down" md="6">
        <v-img :src="require('@/assets/verf-groen.jpeg')" height="100%" />
      </v-col>

      <v-col class="text-center pa-5" cols="12" md="6">
        <v-row class="fill-height" align="center" justify="center">
          <v-col cols="12">
            <!-- <base-bubble-2 style="transform: translateX(55%)" /> -->
            <base-heading class="info--text" style="color:black !important"> Voorbeelden </base-heading>

            <base-text class="mb-5">
              Voorbeelden van situaties waarin ik kan helpen, klik hieronder
              voor een beschrijving.
            </base-text>
          </v-col>

          <v-col class="text-center mb-3" md="6">
            <v-dialog
              v-for="(service, n) in services"
              :key="n"
              v-model="dialogs[n]"
              width="600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-avatar
                    class="elevation-6 mb-2"
                    color="#69A1BB"
                    size="64"
                    tile
                  >
                    <v-icon dark size="52" v-text="service.icon" />
                  </v-avatar>
                  <base-text>
                    <div class="mb-2" v-text="service.name"></div>
                    <!-- <div v-html="service.blurb"></div> -->
                  </base-text>
                </div>
              </template>
              <!-- <v-card>
                <v-card-title>Dialog {{ n }}</v-card-title>
                <v-card-text>This is the content of dialog {{ n }}</v-card-text>
              </v-card> -->
              <v-card>
                <v-card-title>
                  <span class="text-h5" v-text="service.name"></span>
                </v-card-title>
                <v-card-text v-text="service.textlong"></v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "Services",

  data: () => ({
    dialog: false,
    dialogs: Array(5).fill(false),
    services: [
      {
        name: "Voor harde werkers",
        icon: "mdi-account-hard-hat",

        textlong: `Om onzekerheid en de spanning het hoofd te bieden ben je gewend om zeer hard te werken
        en alles perfect te willen doen. Nadeel is dat je steeds alleen maar MOET. Je staat voor
        anderen klaar maar je vergeet je eigen behoeftes. Het maakt dat je minder tijd hebt voor
        dingen die je belangrijk vind en je gedraagt je gespannen en minder vrij. Ook beleef je weinig
        plezier.
        Ik help je om te gaan met de onzekerheid die het leven kan geven. Stress en spanning
        kunnen plaats maken voor rust en ontspanning. Daarin kun je positiever over jezelf denken,
        ook als niet alles perfect gaat..`,
      },
      {
        name: "Voor binnenvetters",
        icon: "mdi-account-arrow-left",
        textlong: `Plannen maken over de toekomst slaat vaak door naar piekeren. Onzekerheid en problemen
        ga je te lijf door er veel over na te denken, zoveel dat je veel in je hoofd leeft. Je bent passief
        of somber en verliest interesse in dingen die je normaal belangrijk vindt. Je staat niet stil bij
        emoties of onderdrukt ze onbewust, of bent gewend om ze te verdoven.
        Ik help je om meer aandacht te hebben voor je lichaam en behoeftes. Daarin oefen je om
        ruimte te maken voor je ervaringen uit het verleden om in het hier en nu gezond te kunnen
        leven. Passiviteit en somberheid kunnen plaats maken voor beweging en zin in het leven.`,
      },
      {
        name: "Voor laatbloeiers",
        icon: "mdi-account-clock",
        textlong: `Je bent al langere tijd overbelast en voelt je moe. Er is teveel gebeurd. Lang hield je
        het vol op de automatische piloot maar door die laatste druppel gaat het niet meer.
        Vermoeidheid en prikkelbaarheid spelen je parten. Je valt stil en vraagt je af hoe
        verder te komen.
        Ik help je door ruimte te creëren voor je verhaal, voor de mooie ervaringen maar ook
        voor de verlieservaringen. Samen met jou zoek ik naar wat je nu zou willen in je
        leven. Daarin leer je de automatische piloot af en leer je meer voor jezelf te zorgen.
        Zo werken we aan je (re)integratie in je plek in de maatschappij.`,
      },
    ],
  }),
};
</script>
