import Vue from "vue";
import Router from "vue-router";
import HomePage from "./Home.vue";
import AanmeldTab from "./Aanmelden.vue";
import Werkwijze from "./Werkwijze.vue";
import Kosten from "./Kosten.vue";
import Kwaliteit from "./Kwaliteit.vue";
import Test from "./Test.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: 'home',
      component: HomePage
    },
    {
      path: "/aanmelden",
      name: 'aanmelden',
      component: AanmeldTab
    },
    {
      path: "/werkwijze",
      name: 'werkwijze',
      component: Werkwijze
    },
    {
      path: "/kosten",
      name: 'kosten',
      component: Kosten
    },
    {
      path: "/kwaliteit",
      name: 'kwaliteit',
      component: Kwaliteit
    },
    {
      path: "/test",
      name: 'test',
      component: Test
    }
  ],
  mode: "history"
});
